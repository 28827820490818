<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <div class="mr-2">
              <v-chip v-if="stage === 3" small label class="border-on radius-small white--text" color="btnColor">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label class="border-on radius-small white--text" color="btnColor">Pending for review</v-chip>
              <v-chip v-else-if="stage === 0" small label class="border-on radius-small white--text" color="btnColor">Open</v-chip>
            </div>
            <div class="">
              <v-chip v-if="metadata.managerlist.indexOf(userid) > -1" small label color="#f4f4f4" class="border-on">Manager</v-chip>
              <v-chip v-else small label color="">General</v-chip>
            </div>
            <div class="ml-1">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <lb-activitylognew :config="activitylogconfig" :url="`/v2/financialclose/project/getusermanagelogs/${metadata._id}`" />
                </span>
              </template>
              <span>Activity Log</span>
            </v-tooltip>
            </div>
            <v-btn class="shadow-off ml-1" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
          </div>

          <div v-if="loading && !metadata._id">
            <v-skeleton-loader
              type="table-heading, list-item-two-line, article, article"
            ></v-skeleton-loader>
          </div>
          <div class="" v-else>
            <div class="mb-4">
              <div class="d-flex py-2 align-center">
                <div class="body-2 font-weight-bold">Associated Users</div>
                <v-spacer></v-spacer>
                <v-btn small color="fbcPrimary" class="shadow-off white--text" @click="associateUserDialogBox()" v-if="managerlist.indexOf(userid) > -1 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))"><v-icon class="mr-1">mdi-plus</v-icon>User</v-btn>
              </div>
              <DataTable
                v-if="finaluserlist.length > 0"
                :headers="headers"
                :items="formatedData"
                :enableslot="['action', 'tasklist', 'progress','type']"
                :loading="loading"
                :bgColor="true"
              >
              <template v-slot:type="{ item }">
                <v-chip small label v-if="item.type === 'manager'" color="lightblue" class="mr-1 Tabblue--text" >Manager</v-chip>
                  <v-chip small label v-else-if="item.type === 'general'" color="lightorange" class="mr-1 orange--text">General</v-chip>
                  <v-chip small label v-else color="lightred" class="error--text" >Unknown</v-chip>
              </template>
              <template v-slot:tasklist="{ item }">
              <v-tooltip bottom content-class="tooltip-bottom" v-if="(item.tasklist || {}).preparer">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip small color="lightblue" class="mr-1 Tabblue--text" v-bind="attrs" v-on="on">{{item.tasklist.preparer}}</v-chip>
                </template>
                <span>Preparer</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="(item.tasklist || {}).approver">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip small color="lightorange" class="mr-1 orange--text" v-bind="attrs" v-on="on">{{item.tasklist.approver}}</v-chip>
                </template>
                <span>Approver</span>
              </v-tooltip>
              </template>
              <template v-slot:progress="{ item }">
                <div v-if="(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0)) > 0" style="max-width:100px;margin:auto">
                  {{ (((((item.progress || {}).completed || 0)/(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0))) || 0)*100).toFixed(0) }}%<v-progress-linear
                    :height="10" color="success"
                    :value="Math.max(((((item.progress || {}).completed || 0)/(((item.progress || {}).preparer || 0) + ((item.progress || {}).approver || 0))) || 0)*100,2)">
                  </v-progress-linear>
                </div>
                <div v-else>NA</div>
              </template>

              <template v-slot:action="{ item }">
                <div class="" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                 <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid && managerlist.indexOf(userid) > -1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" @click="manageUser('move', item.type, item._id)"><v-icon>mdi-sync</v-icon></v-btn>
                  </template>
                  <span v-if="item.type === 'general'">Change to Manager User</span>
                  <span v-else>Change to General User</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom" v-if="item._id!==userid && managerlist.indexOf(userid) > -1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" @click="deleteUserDialogBox(item)"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Remove User</span>
                </v-tooltip>                          
                <v-tooltip bottom content-class="tooltip-bottom" v-if="managerlist.indexOf(userid) > -1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on" @click="replaceUserDialog(item._id, item.type)"><v-icon>mdi-account-convert</v-icon></v-btn>
                  </template>
                  <span>Replace User</span>
                </v-tooltip>     
                </div>
              </template>
              </DataTable>
              <div v-else class="subtitle-1 grey--text pa-4">No Data</div>
            </div>
            <div class="mb-4">
              <div class="d-flex py-2 align-center">
                <div class="body-2 font-weight-bold">Information Owners</div>
                <v-spacer></v-spacer>
              </div>
              <DataTable
                v-if="infoowners.length > 0"
                :headers="headersOne"
                :items="formatedDataOne"
                :enableslot="['action', 'information', 'progress']"
                :loading="loading"
                :bgColor="true"
              >
               <template v-slot:information="{ item }">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small color="fbcPrimary" class="mr-1" v-bind="attrs" v-on="on">{{item.requested+item.pending+item.approved+item.cancelled}}</v-chip>
                    </template>
                    <span>Total requests</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small color="lightblue" class="mr-1 Tabblue--text" v-bind="attrs" v-on="on">{{item.requested}}</v-chip>
                    </template>
                    <span>Yet to respond</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small color="lightorange" class="mr-1 orange--text" v-bind="attrs" v-on="on">{{item.pending}}</v-chip>
                    </template>
                    <span>Waiting for approval</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small color="lightgreen" class="mr-1 teect-green" v-bind="attrs" v-on="on">{{item.approved}}</v-chip>
                    </template>
                    <span>Approved</span>
                  </v-tooltip>
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small color="lightred" class="mr-1 error--text" v-bind="attrs" v-on="on">{{item.cancelled}}</v-chip>
                    </template>
                    <span>Cancelled</span>
                  </v-tooltip>
               </template>

                <template v-slot:progress="{ item }">
                  <div style="max-width:100px;margin:auto">
                    {{ ((((item.approved)/(item.requested+item.pending+item.approved+item.cancelled)) || 0)*100).toFixed(0) }}%
                    <v-progress-linear
                      :height="10" color="success"
                      :value="Math.max((((item.approved)/(item.requested+item.pending+item.approved+item.cancelled)) || 0)*100,2)">
                    </v-progress-linear>
                   </div>
                </template>
                <template v-slot:action="{ item }">
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="managerlist.indexOf(userid) > -1 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="replaceInfoOwnerDialog(item)"><v-icon>mdi-account-convert</v-icon></v-btn>
                    </template>
                    <span>Replace Information Owner</span>
                  </v-tooltip>
                </template>
              </DataTable>
              <div v-else class="subtitle-1 grey--text pa-4">No Data</div>
              
            </div>
          </div>

        </v-card-text>
      </v-card>

      <lb-dailogboxnew v-model="infoownerdialog" heading="Change Information Owner" width="400" :loading="infoownerloading">
        <template v-slot:body>
          <div>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown v-model="infoownerchangetype" label="Type*" :items="[{name: 'New', value: 'new'},{name: 'Existing', value: 'existing'}]" itemtext="name"
                  itemvalue="value" />
              </v-col>
              <v-col cols="12" class="my-0 py-0" v-if="infoownerchangetype === 'existing'">
                <lb-dropdown label="New Owner*" v-model="infoownerdata.newownerid" :items="possibleinfoowners" itemtext="name"
                  itemvalue="_id" />
              </v-col>
              <v-col cols="12" class="my-0 py-0" v-if="infoownerchangetype === 'new'">
                <lb-string label="Name*" v-model="infoownerdata.name"/>
              </v-col>
              <v-col cols="12" class="my-0 py-0" v-if="infoownerchangetype === 'new'">
                <lb-string label="Email*" v-model="infoownerdata.email"/>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn color="bntColor" class="white--text" small @click="replaceInfoOwner()" :disabled="!(infoownerdata.newownerid || (infoownerdata.name && infoownerdata.email))">Update</v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="associateuserdialog" heading="Add User" width="400" :loading="associateuserloading">
        <template v-slot:body>
          <div class="d-flex align-center justify-center ">
            <v-row>
              <v-col cols="12" class="my-1 py-0">
                <lb-dropdown label="User*"  v-model="associateuserdata.userid" :items="associateuserlist" itemtext="name"
                  itemvalue="_id" />
              </v-col>
              <v-col cols="12" class="my-1 py-0">
                <lb-dropdown v-model="associateuserdata.type" label="Type*" :items="usertypes" itemtext="name"
                  itemvalue="value" />
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn color="btnColor" class="white--text" small @click="manageUser('associate')" :disabled="!associateuserdata.type || !associateuserdata.userid">Add</v-btn>
        </template>
      </lb-dailogboxnew>
  
      <lb-dailogboxnew v-model="replaceuserdialog" heading="Replace User" width="500" :loading="replaceuserloading">
        <template v-slot:body>
          <div>
            <div class="mb-3">
              <span class="font-weight-bold mr-2">From User:</span>
              <span class="">{{(userdata[replaceuserid] || {}).displayname || 'Unknown'}}</span>
            </div>
            <lb-dropdown label="To User*" v-model="replaceuserdata.userid" :items="replaceuserlist" itemtext="displayname" itemvalue="_id" />
            <div v-if="(userchecklistcount[replaceuserid] || {}).preparer || (userchecklistcount[replaceuserid] || {}).approver">
              <div>Since the user is also associated to various tasklist items, all those association will be replaced with the new user.</div>
              <div><span class="font-weight-bold mr-2">Note:</span>If the new user is already assigned to the tasklist item in a different capacity, then they will not be re-assigned.</div>
              <div class="font-weight-bold">Do you want to proceed?</div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn color="fbcPrimary" class="white--text" small @click="replaceUser()" :disabled="!replaceuserdata.userid">Replace</v-btn>
        </template>
      </lb-dailogboxnew>

      <lb-dailogboxnew v-model="deleteuserdialog" heading="Remove User" width="500" :loading="deleteuserloading">
        <template v-slot:body>
          <div>
            <div class="mb-3">
              <span class="font-weight-bold mr-2">Selected User:</span>
              <span class="">{{(deleteuserdata || {}).name || 'Unknown'}}</span>
            </div>
            <div v-if="(userchecklistcount[deleteuserdata._id] || {}).preparer || (userchecklistcount[deleteuserdata._id] || {}).approver">
              Since the user is also associated to various tasklist items, all those association will be removed if the user is removed from the project.
            </div>
            <div v-else>This will remove the user from the current project.</div>
            <div class="font-weight-bold">Do you want to proceed?</div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="error" small @click="manageUser('remove', deleteuserdata.type, deleteuserdata._id)()">Remove</v-btn>
        </template>
      </lb-dailogboxnew>

    </div>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  name: 'financialclose_project_users',
  // props: ['props'],
  data: function(){
    return {
      right:"financialclose_project",
      metadata: {},
      checklist: [],
      pbcdata: {},
      userchecklistcount: {},
      userid: "",
      formdata: {},
      userdata: {},
      stage: -1,      
      id: "",
      loading: false,

      associateuserdialog: false,
      associateuserloading: false,
      associateuserdata: {},
      associateuserlist: [],
      infoownerchangetype: "",
      oldinfoownerdata: {},
      infoownerdialog: false,
      infoownerloading: false,
      infoownerdata: {},
      allinfoowners: [],
      userlist: [],
      managerlist: [],
      infoowners: [],
      possibleinfoowners: [],
      replaceuserdialog: false,
      replaceuserloading: false,
      replaceuserid: "",
      replaceuserdata: {},
      replaceuserlist: [],
      deleteuserdialog: false,
      deleteuserloading: false,
      deleteuserdata: {},
      usertypes: [
        { name: "General user", value: "general" },
        { name: "Manager user", value: "manager" }
      ],
      headers: [
        {
          value: "indexno",
          text: "SL No",
          sortable: false,
          filterable: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
        },
        {
          value: "email",
          text: "Email",
          sortable: true,
          filterable: true,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          filterable: true,
          alignment: "text-center",
        },
        {
          value: "tasklist",
          text: "Tasklist Items",
          alignment: "text-center",
        },
        {
          value: "progress",
          text: "Progress",
          alignment: "text-center",
        },
      ],
      headersOne: [
        {
          value: "indexno",
          text: "SL No",
          sortable: false,
          filterable: false,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
        },
        {
          value: "email",
          text: "Email",
          sortable: true,
          filterable: true,
        },
        {
          value: "information",
          text: "Information Requests",
          alignment: "text-center",
        },
        {
          value: "progress",
          text: "Progress",
          alignment: "text-center",
        },
      ],
      formatedData: [],
      formatedDataOne: [], 
      headersPushed: false,
      activitylogconfig: {
        userassociate: {
          icon: "mdi-plus",
          color: "info",
          displaytext: "User Associated",
        },
        userreplaced: {
          icon: "mdi-account-convert",
          color: "info",
          displaytext: "User Replaced",
        },
        userremoved: {
          icon: "mdi-close",
          color: "error",
          displaytext: "User Removed",
        },
        usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
      },
    }
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.finaluserlist.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          name: item.name,
          email: item.email,
          color: item.color,
          type: item.type,
          tasklist: this.userchecklistcount[item._id],
          progress: this.userchecklistcount[item._id],
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    formateDataOne(){
      let ObjectTempone = {};
      let ArrayTempone = [];
      this.infoowners.forEach((item, index) => {
        ObjectTempone = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          name: item.info_owner_detail.name,
          email: item.info_owner_detail.email,
          approved:item.approved,
          cancelled:item.cancelled,
          pending:item.pending,
          requested:item.requested,
          info_owner_detail:item.info_owner_detail,
          info_owner:item.info_owner,
        };
        ArrayTempone.push(ObjectTempone);
      });
      
      this.formatedDataOne = ArrayTempone;
    },
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/financialclose/analysis/getuserdetails/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.$store.commit("setProjectName", data.metadata);
          this.userlist = [];
          this.userlist = this.metadata.userlist || [];
          this.managerlist = [];
          this.managerlist = this.metadata.managerlist || [];
          this.pushHeader();
          this.stage = this.metadata.stage;
          this.userchecklistcount = data.userchecklistcount || {};
          this.infoowners = data.infoowners || [];
          this.formateDataOne();
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).then(() => {
        return this.axios.post("/v2/financialclose/analysis/getusersformdata");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.formdata = dt.data.data[0] || {};
          this.allinfoowners = this.formdata.infoowners || [];
          console.log(this.allinfoowners);
          this.userdata = {};
          let colors = this.$nova.getColors((this.formdata.users || []).length, 0.2);
          // console.log(colors);
          for (let i = 0; i < (this.formdata.users || []).length; i++) {
            const el = (this.formdata.users || [])[i];
            this.userdata[el._id] = el;
            this.userdata[el._id].color = colors[i];            
          }
          this.formateData();
        }else throw new Error(dt.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      }).finally(() => {
        this.loading = false;
      })
    },
    deleteUserDialogBox(data) {
      this.deleteuserdata = {...data};
      this.deleteuserdialog = true;
    },
    associateUserDialogBox() {
      this.associateuserdata = {};
      this.associateuserlist = [];
      for (const k in this.formdata.users) {
        if (Object.hasOwnProperty.call(this.formdata.users, k)) {
          const el = this.formdata.users[k];
          if(this.managerlist.indexOf(el._id) === -1 && this.userlist.indexOf(el._id) === -1) this.associateuserlist.push(el);
        }
      }
      this.associateuserdialog = true;
    },
    replaceInfoOwnerDialog(d) {
      this.infoownerdata = {};
      this.oldinfoownerdata = d;
      this.possibleinfoowners = [];
      for (const i of this.allinfoowners) {
        if(i._id !== d._id) this.possibleinfoowners.push(i);
      }
      this.infoownerchangetype = "existing";
      this.infoownerdialog = true;
    },
    replaceInfoOwner() {
      this.infoownerdata.oldownerid = this.oldinfoownerdata._id;
      this.infoownerdata.type = this.infoownerchangetype;
      this.infoownerloading = true;
      this.axios.post("/v2/financialclose/project/manageinfoowner/" + this.metadata.pbcproject, {data: [this.infoownerdata]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Information owner changed");
          this.getData()
        }else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.infoownerloading = false;
        this.infoownerdialog = false;
      });
    },

    manageUser(type, usertype = "general", uid = "", fromuserid = "") {
      let data = {};
      if (type === "associate") {
        this.associateuserloading = true;
        data = { userid: this.associateuserdata.userid, usertype: this.associateuserdata.type, type: "associate" };
      } else if (type === "move") {
        this.loading = true;
        data = { userid: uid, usertype: usertype, type: "move" };
      } else if (type === "remove") {
        this.deleteuserloading = true;
        data = { userid: uid, usertype: usertype, type: "remove" };
      } else if (type === "replace") {
        this.deleteuserloading = true;
        this.replaceuserloading = true;
        data = { touserid: uid, usertype: usertype, type: "replace", fromuserid: fromuserid };
      }
      let successflag = false;
      console.log(data,"data");
      this.axios.post("/v2/financialclose/project/manageuser/" + this.metadata._id, { data: [data] })
        .then(dt => {
          if (dt.data.status === "success") {
            this.associateuserdialog = false;
            this.deleteuserdialog = false;
            const successMessages = {
              associate: "User Associated",
              move: "User Changed",
              remove: "User Removed",
              replace: "User Replaced User"
            };
            this.$store.commit("sbSuccess", successMessages[type]);
            if (type === "replace") {
              this.replaceuserloading = false;
              this.replaceuserdialog = false;
              successflag = true;
            }
            this.refreshData();
          } else {
            throw new Error(dt.data.message || "Error changing status");
          }
        })
        .catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.deleteuserloading = false;
          this.associateuserloading = false;
          if (successflag) this.refreshData();
        });
  },

    // manageUser(type, usertype='general', uid="") {
    //   let data = {}
    //   if(type === "associate") {
    //     this.associateuserloading = true;
    //     data = {userid: this.associateuserdata.userid, usertype: this.associateuserdata.type, type: "associate"};
    //   }
    //   else if(type === "move") {
    //     this.loading = true;
    //     data = {userid: uid, usertype: usertype, type: "move"};
    //   }
    //   else if(type === "remove") {
    //     this.deleteuserloading = true;
    //     data = {userid: uid, usertype: usertype, type: "remove"};
    //   }
    //   let successflag = false;
    //   this.axios.post("/v2/financialclose/project/manageuser/"+this.metadata._id, {data: [data]}).then(dt => {
    //     if(dt.data.status === "success"){
    //       this.associateuserdialog = false;
    //       this.deleteuserdialog = false;
    //       if(type === "associate")this.$store.commit("sbSuccess", "User Associated");
    //       else if(type === "move") this.$store.commit("sbSuccess", "User Changed");
    //       else if(type === "remove") this.$store.commit("sbSuccess", "User Removed");
    //       successflag = true;
    //     }else throw dt.data.message || "Error changing status";
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.loading = false;
    //     this.deleteuserloading = false;
    //     this.associateuserloading = false;
    //     if(successflag) this.refreshData();
    //   });
    // },
    replaceUserDialog(uid, type) {
      this.replaceuserdata = {type: type};
      this.replaceuserlist = [];
      this.replaceuserid = uid;
      for (const k in this.formdata.users) {
        if (Object.hasOwnProperty.call(this.formdata.users, k)) {
          const el = this.formdata.users[k];
          if(el._id !== uid) this.replaceuserlist.push(el);
        }
      }
      this.replaceuserdialog = true;
    },
     replaceUser(){
      let fromuserid = this.replaceuserid;
      let touserid = this.replaceuserdata.userid;
      let type = this.replaceuserdata.type;
      // console.log(fromuserid,"fromuserid");
      // console.log(touserid,"touserid");
      // console.log(type,"fromuserid");
      this.manageUser("replace",type,touserid,fromuserid)
    },
    // replaceUser() {
    //   let type = this.replaceuserdata.type;
    //   if(["manager", "general"].indexOf(type) === -1) this.$store.commit("sbError", "Invalid Type");
    //   else{
    //     let fromuserid = this.replaceuserid;
    //     let touserid = this.replaceuserdata.userid;
    //     this.replaceuserloading = true;
    //     let data = {fromuserid: fromuserid, touserid: touserid, type: type};
    //     this.axios.post("/v2/financialclose/project/replaceuser/"+this.metadata._id, {data: [data]}).then(dt => {
    //       if(dt.data.status === "success"){
    //         this.userlist = [];
    //         this.managerlist = [];
    //         this.refreshData();
    //         this.replaceuserdialog = false;
    //         this.$store.commit("sbSuccess", "User Replaced");
    //       }else throw dt.data.message || "Error changing status";
    //     }).catch(err => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     }).finally(() => {
    //       this.replaceuserloading = false;
    //     });
    //   }
    // },
    pushHeader(){
      if (this.managerlist.indexOf(this.userid) > -1 && !this.headersPushed && this.metadata.stage === 0) {
          this.headers.push({ value: "action",text: "Action",sortable: false,datatype: "action",alignment: "text-center"}) 
          this.headersOne.push({ value: "action", text: "Action", sortable: false, datatype: "action",alignment: "text-center"});
          this.headersPushed = true;
      }  else {
        return this.headers;
      }
    },
  },
  computed: {
    finaluserlist(){
      let nl = [];
      for (const i of this.managerlist) {
        let ud = {...this.userdata[i]};
        ud.type = "manager";
        nl.push(ud);
      }
      for (const i of this.userlist) {
        let ud = {...this.userdata[i]};
        ud.type = "general";
        nl.push(ud);
      }
      return nl;
    }
  },
  watch: {
    "infoownerchangetype": function(d){
      this.infoownerdata.type = d;
    }
  }
}
</script>
